<template>
<div class="content-onglets">
    <TinyHTML v-if="$i18n.locale == 'de' && data['indication_de']" :html="data['indication_de']"/>
    <TinyHTML v-else-if="$i18n.locale == 'fr' && data['indication_fr']" :html="data['indication_fr']"/>
    <TinyHTML v-else-if="data['indication_en']" :html="data['indication_en']"/>  
    <TinyHTML v-else-if="data['indication_original']" :html="data['indication_original']"/>

    <div class="box-toggle textJustify" v-if="same_as_ma">  
        <a href="javascript:void(0);" class="title title-tb"  @click="same_as_ma_Show = !same_as_ma_Show">                             
        {{$t('sane_as_ma')}} <em :class="same_as_ma_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>                          
        </a>                       
        <div v-if="same_as_ma_Show">{{same_as_ma}}</div>

    </div>            
</div>
</template>

<script>
import TinyHTML from './tinymce_html.vue'
export default {
    name : "Indication",
    components : {
        TinyHTML
    },
    data(){
        return {
            same_as_ma_Show : false
        }
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        }
    },
    props : {
        same_as_ma : {
            type : String,
            default : ''
        }
    }
}
</script>

<style>

</style>